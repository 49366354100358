<template>
	<div v-if="company">
		<v-toolbar
			color="primary"
			dark
			flat
		>
			<v-tabs
				v-model="currentTab"
				centered
				center-active
				show-arrows
				optional
			>
				<v-tabs-slider />
				<v-tab
					v-for="tab in filteredTabs"
					:key="tab.name"
					:to="{ name: tab.route }"
					v-text="tab.title"
					exact-path
				/>
			</v-tabs>
		</v-toolbar>

		<template v-if="($route.name === 'company')">
			<v-container class="container--narrow">
				<CompanyContestNotification v-if="company._available_contests && company._available_contests.length" />
				<v-card>
					<v-card-text>
						<FilterableList
							:items="company._details"
							:enable-click="(false)"
							:enable-search="(false)"
							no-results-text=""
						/>
					</v-card-text>
					<v-divider />
					<v-card-actions>
						<v-spacer />
						<v-btn
							color="success"
							text
							:to="{ name: 'companyEdit' }"
						>
							<v-icon left>
								mdi-pencil
							</v-icon>
							Muokkaa tietoja
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-container>
		</template>

		<!-- Output child page -->
		<router-view
			:key="$route.fullPath"
			:company="company"
		/>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import FilterableList from '@/components/FilterableList'
import CompanyContestNotification from '@/components/CompanyContestNotification'

export default {
	name: 'Company',

	components: {
		FilterableList,
		CompanyContestNotification,
	},

	data: () => ({
		company: null,
		currentTab: 0,
		tabs: [],
	}),

	computed: {
		...mapState({
			config: state => state.config,
			user: state => state.user,
			companies: state => state.companies,
		}),

		filteredTabs () {
			let tabs = this.tabs
			return tabs
		},
	},

	mounted () {
		this.setCompany()

		this.tabs = [
			{
				name: 'details',
				title: 'Tiedot',
				route: 'company',
			},
			{
				name: 'tracking',
				title: 'Seuranta',
				route: 'company-tracking',
			},
			{
				name: 'contest',
				title: 'Yrityskilpailu',
				route: 'company-contest',
			},
			{
				name:'studyCredits',
				title: ' Opintopistehakemus',
				route: 'company-study-credits',
			},
			{
				name: 'insurances',
				title: 'Vakuutukset',
				route: 'company-insurances',
			},
			{
				name: 'invoices',
				title: 'Laskut',
				route: 'company-invoices',
			},
		].filter(item => item !== null)
		this.$store.commit('setBackButtonHistoryMode', false)

		this.$root.$emit('setHeadline', this.company.title)
	},

	methods: {
		setCompany () {
			this.company = this.companies.find(company => {
				return company.name == this.$route.params.pagename
			})

			if (!this.company) {
				this.$router.replace({ name: 'Error404' })
			}
		},
	},
}
</script>
