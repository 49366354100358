<template>
	<div>
		<v-container class="container--narrow">
			<template v-if="loading">
				Ladataan...
			</template>
			<template v-else-if="error">
				<p>{{ error }}</p>
			</template>
			<template v-else>
				<v-card>
					<v-card-text>
						<p>{{ item.summary }}</p>

						<FilterableList
							v-if="item.details.length"
							:items="item.details"
							:enable-click="(false)"
							:enable-search="(false)"
						/>
					</v-card-text>
				</v-card>
			</template>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'
import FilterableList from '@/components/FilterableList'

export default {
	name: 'News',
	components: {
		FilterableList,
	},
	data: () => ({
		loading: true,
		error: '',
		item: {},
	}),
	computed: {
		...mapState([
			'benefits',
		]),
	},
	mounted () {
		try {
			// Find benefit item from the store
			this.item = this.benefits.find(item => {
				return item.name == this.$route.params.pagename
			})

			if (!this.item) throw 'Jäsenetua ei löytynyt!'

			// Set headline
			this.$root.$emit('setHeadline', this.item.title)

			this.loading = false
		} catch (error) {
			this.error = error
			this.loading = false
		}
	},
}
</script>
