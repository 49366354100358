<i18n>
{
	"en": {
		"titles": {
			"invoiceDetails": "Invoice details",
			"invoiceRows": "Invoice rows"
		},
		"notifications": {
			"noInvoiceRows": "Invoice has no rows",
			"paymentCanceled": "Payment was canceled",
			"paymentErrorOccured": "An error occured during the payment"
		},
		"buttons": {
			"payInvoice": "Pay invoice"
		},
		"paymentDialog": {
			"proceedToPayment": "Proceed to payment",
			"instructions": "You will be redirected to Svea Payment service, where you can pay your invoice. Please don't close the browser after payment to make sure that your payment will be successfully saved.",
			"creatingPayment": "Creating payment",
			"redirectingToPaymentService": "Redirecting to payment service",
			"redirectInfo": "If the automatic redirection does not work, please click the button below.",
			"checkingPayment": "Checking payment"
		}
	},
	"fi": {
		"titles": {
			"invoiceDetails": "Laskun tiedot",
			"invoiceRows": "Laskurivit"
		},
		"notifications": {
			"noInvoiceRows": "Laskulla ei ole rivejä",
			"paymentCanceled": "Maksu peruutettiin",
			"paymentErrorOccured": "Maksussa tapahtui virhe"
		},
		"buttons": {
			"payInvoice": "Maksa lasku"
		},
		"paymentDialog": {
			"proceedToPayment": "Siirry maksamaan",
			"instructions": "Laskun maksaminen tapahtuu Svea Paymentin maksupalvelussa. Älä sulje selainta laskun maksamisen jälkeen, jotta maksutapahtuma kirjautuu oikein.",
			"creatingPayment": "Luodaan maksutapahtumaa",
			"redirectingToPaymentService": "Siirrytään maksupalveluun",
			"redirectInfo": "Jos automaattinen uudelleenohjaus ei toimi, klikkaa alla olevaa nappia.",
			"checkingPayment": "Tarkistetaan maksutapahtumaa"
		}
	}
}
</i18n>

<template>
	<div v-if="invoice">
		<v-container
			v-if="invoice._details"
			class="container--narrow"
		>
			<v-card>
				<v-card-title>
					{{ $i18n.t('titles.invoiceDetails') }}
				</v-card-title>
				<v-divider />
				<v-card-text v-if="invoice._details && invoice._details.length">
					<FilterableList
						:items="invoice._details"
						:enable-click="false"
						:enable-search="false"
						subtitle-src="label"
						title-src="value"
					/>
				</v-card-text>
				<v-divider />
				<v-card-actions v-if="invoice._is_paid === false && invoice._is_payable === true">
					<v-btn
						color="primary"
						text
						@click="createPaymentDialogOpen = true"
					>
						<v-icon left>
							mdi-currency-eur
						</v-icon>
						{{ $i18n.t('buttons.payInvoice') }}
					</v-btn>

					<!-- Create payment dialog -->
					<v-dialog
						v-model="createPaymentDialogOpen"
						max-width="400"
						persistent
						scrollable
					>
						<v-card>
							<v-card-title>
								{{ $i18n.t('buttons.payInvoice') }}
							</v-card-title>
							<v-divider />
							<v-card-text>
								<Spinner
									v-if="paymentLoading === true"
									:text="paymentLoadingText"
								/>
								<template v-else>
									{{ $i18n.t('paymentDialog.instructions') }}
								</template>
								<!-- Payment form. Will be populated after successful payment creation -->
								<form
									v-if="paymentFormData && Object.keys(paymentFormData).length"
									ref="paymentForm"
									method="post"
									class="paymentForm"
									:action="paymentFormData.action"
									aria-hidden="true"
								>
									<v-alert
										type="info"
										dense
										class="mt-6"
									>
										{{ $i18n.t('paymentDialog.redirectInfo') }}
									</v-alert>
									<div class="text-center">
										<v-btn
											type="submit"
											color="primary"
										>
											<v-icon left>
												mdi-arrow-right
											</v-icon>
											{{ $i18n.t('paymentDialog.proceedToPayment') }}
										</v-btn>
									</div>
									<div
										v-if="paymentFormData.fields && Object.keys(paymentFormData.fields).length"
										class="paymentForm__fields"
									>
										<div
											v-for="(value, name, index) in paymentFormData.fields"
											:key="index"
											style="display: table-row;"
										>
											<label
												for="name"
												style="display: table-cell; width: 30%;"
											>
												{{ name }}
											</label>
											<input
												:id="name"
												:name="name"
												:value="value"
												style="display: table-cell; width: 100%;"
											>
										</div>
									</div>
								</form>
							</v-card-text>
							<v-divider />
							<v-card-actions>
								<v-btn
									text
									:disabled="paymentLoading"
									@click="createPaymentDialogOpen = false"
								>
									{{ $i18n.t('general.cancel') }}
								</v-btn>
								<v-spacer />
								<v-btn
									color="primary"
									text
									:disabled="paymentLoading"
									@click="createPayment"
								>
									{{ $i18n.t('paymentDialog.proceedToPayment') }}
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>

					<!-- Check payment dialog -->
					<v-dialog
						v-model="checkPaymentDialogOpen"
						max-width="300"
						persistent
						scrollable
					>
						<v-card>
							<v-card-text>
								<Spinner
									:text="$i18n.t('paymentDialog.checkingPayment')"
								/>
							</v-card-text>
						</v-card>
					</v-dialog>
				</v-card-actions>
			</v-card>
		</v-container>

		<v-container class="container--narrow">
			<v-card>
				<v-card-title>
					{{ $i18n.t('titles.invoiceRows') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<v-data-table
						:headers="invoice._table_headers"
						:items="invoice._table_rows"
						:disable-sort="true"
						:disable-pagination="true"
						:hide-default-footer="true"
						:mobile-breakpoint="400"
						:no-data-text="$i18n.t('notifications.noInvoiceRows')"
					>
						<!-- eslint-disable -->
						<template #item.total="slotProps">
						<!-- eslint-enable -->
							<span v-html="slotProps.item.total" />
						</template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</v-container>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'CompanyInvoice',

	props: {
		company: {
			type: Object,
			required: true,
		},

		invoiceId: {
			type: [Number, String],
			required: true,
		},
	},

	data: () => ({
		invoice: null,
		createPaymentDialogOpen: false,
		paymentFormData: {},
		paymentLoading: false,
		paymentLoadingText: null,
		checkPaymentDialogOpen: false,
	}),

	computed: {
		...mapState({
			config: state => state.config,
			user: state => state.user,
			companies: state => state.companies,
		}),

		invoices () {
			return this.company.invoice
		},
	},

	mounted () {
		this.setInvoice()

		// Check payment status
		if (Object.keys(this.$route.query).length) {
			this.$nextTick(() => {
				this.$store.commit('setBackButtonHistoryMode', false)
			})

			if (this.$route.query.status === 'ok') {
				this.checkPayment(this.$route.query)
			} else if (this.$route.query.status === 'cancel') {
				this.$store.dispatch('setNotifyMessage', this.$i18n.t('notifications.paymentCanceled'))
			} else if (this.$route.query.status === 'error') {
				this.$store.dispatch('setNotifyError', this.$i18n.t('notifications.paymentErrorOccured'))
			}

			// Clear query string
			this.$router.replace({'query': null})
		}
	},

	methods: {
		setInvoice () {
			this.invoice = this.invoices.find(invoice => {
				return invoice.id == this.invoiceId
			})

			if (!this.invoice) {
				this.$router.replace({ name: 'Error404' })
			}
		},

		// Create a new payment and redirect user to Svea Payment
		createPayment () {
			this.paymentLoading = true
			this.paymentLoadingText = this.$i18n.t('paymentDialog.creatingPayment')

			// Define base URL for the current page
			const baseUrl = window.location.href.split(/\?|#/)[0]

			// Request payment data
			this.$api.Invoice.doRequest({
				method: 'POST',
				url: 'createpayment/',
				body: {
					invoice_id: this.invoice.id,
					pmt_okreturn: baseUrl + '?status=ok',
					pmt_errorreturn: baseUrl + '?status=error',
					pmt_cancelreturn: baseUrl + '?status=cancel',
					pmt_delayedpayreturn: baseUrl + '?status=cancel',
				},
			}).on('done', res => {
				if (!res.body.error) {
					// Populate payment form
					this.paymentFormData = Object.assign({}, res.body.paymentFormData)

					// Make sure that form is fully rendered and then post it
					this.$nextTick(() => {
						this.paymentLoadingText = this.$i18n.t('paymentDialog.redirectingToPaymentService')

						if (this.$refs.paymentForm) {
							this.$refs.paymentForm.submit()
						}
					})
				} else {
					this.paymentLoading = false
				}
			}).on('fail', () => {
				this.paymentLoading = false
			})
		},

		// Check payment status
		checkPayment (data = {}) {
			this.checkPaymentDialogOpen = true

			this.$api.Invoice.doRequest({
				method: 'POST',
				url: 'checkpayment/',
				body: data,
			}).on('done', () => {
				// we should invoke the method found in parent component via $parent or use bus emission
				this.$api.Companies.doRequest()

				this.$nextTick(() => {
					this.$store.dispatch('clearConfirmNavigation')
					// this.$router.push({ name: 'company-insurances', replace: true })
					this.$router.push({ name: 'companyEdit' })
				})
			}).on('finish', () => {
				this.checkPaymentDialogOpen = false
			})
		},
	},
}
</script>

<style lang="scss">
.paymentForm {
	&__fields {
		overflow: hidden;
		height: 0;
	}

}
</style>
