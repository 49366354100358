import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Error404 from '@/views/Error404.vue'
import MembershipCard from '@/views/MembershipCard.vue'
import MyAccount from '@/views/MyAccount.vue'
import MemberDetails from '@/views/MemberDetails.vue'
import MemberAddNew from '@/views/MemberAddNew.vue'
import Settings from '@/views/Settings.vue'
import BenefitContainer from '@/views/BenefitContainer.vue'
import Benefit from '@/views/Benefit.vue'
import NewsContainer from '@/views/NewsContainer.vue'
import ClubContainer from '@/views/ClubContainer.vue'
import Club from '@/views/Club.vue'
import CompanyContainer from '@/views/CompanyContainer.vue'
import Company from '@/views/Company.vue'
import CompanyEdit from '@/views/CompanyEdit.vue'
import CompanyAddNew from '@/views/CompanyAddNew.vue'
import CompanyParticipation from '@/views/CompanyParticipation.vue'
import CompanyTracking from '@/views/CompanyTracking.vue'
import CompanyContest from '@/views/CompanyContest.vue'
import CompanyInsurances from '@/views/CompanyInsurances.vue'
import StudyCredits from '@/views/StudyCredits.vue'
// import CompanyInsurance from '@/views/CompanyInsurance.vue'
import CompanyInvoices from '@/views/CompanyInvoices.vue'
import CompanyInvoice from '@/views/CompanyInvoice.vue'
import FormPageContainer from '@/views/FormPageContainer.vue'
import FormItem from '@/views/FormItem.vue'
import AssignmentContainer from '@/views/AssignmentContainer.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: {
			name: 'membershipCard',
		},
		meta: {
			hidden: true,
		},
	},
	{
		path: '/membershipCard',
		name: 'membershipCard',
		component: MembershipCard,
		meta: {
			title: 'Jäsenkortti',
			icon: 'mdi-badge-account-horizontal',
		},
	},
	{
		path: '/settings',
		name: 'settings',
		component: Settings,
		meta: {
			title: 'Asetukset',
			headline: 'Asetukset',
			icon: 'mdi-cog',
			hidden: true,
		},
	},
	{
		path: '/benefits',
		name: 'benefitContainer',
		component: BenefitContainer,
		meta: {
			title: 'Jäsenedut',
			headline: 'Jäsenedut',
			icon: 'mdi-star',
		},
		children: [
			{
				path: ':pagename',
				name: 'benefit',
				component: Benefit,
				props: true,
				meta: {
					title: 'Jäsenetu',
					headline: 'Jäsenetu',
				},
			},
		],
	},
	{
		path: '/news',
		name: 'newsContainer',
		component: NewsContainer,
		meta: {
			title: 'Uutiset',
			headline: 'Uutiset',
			icon: 'mdi-bullhorn',
		},
	},
	{
		path: '/memberdetails',
		name: 'memberDetails',
		component: MemberDetails,
		meta: {
			title: 'Jäsentiedot',
			headline: 'Jäsentiedot',
			icon: 'mdi-account',
		},
		children: [
			{
				path: 'addnew',
				name: 'addMember',
				component: MemberAddNew,
				meta: {
					title: 'Lisää uusi jäsen',
					headline: 'Lisää uusi jäsen',
				},
			},
			{
				path: 'edit/:id',
				name: 'myAccount',
				component: MyAccount,
				props: true,
				meta: {
					title: 'Muokkaa jäsentietoja',
					headline: 'Muokkaa jäsentietoja',
					icon: 'mdi-account',
					hidden: false,
				},
			},
		],
	},
	{
		path: '/forms',
		name: 'formPageContainer',
		component: FormPageContainer,
		meta: {
			title: 'Lomakkeet',
			headline: 'Lomakkeet',
			icon: 'mdi-pencil',
			hidden: true,
		},
		// todo: proper controllers
		children: [
			{
				path: ':slug',
				name: 'form-item',
				component: FormItem,
				props: true,
				meta: {
					title: 'Lomake',
				},
			},
		],
	},
	{
		path: '/clubs',
		name: 'clubContainer',
		component: ClubContainer,
		meta: {
			hidden: true,
			title: 'Kerhot',
			headline: 'Kerhot',
			icon: 'mdi-heart',
		},
		children: [
			{
				path: ':pagename',
				name: 'club',
				component: Club,
				props: true,
				meta: {
					title: 'Kerho',
				},
			},
		],
	},
	{
		path: '/assignments',
		name: 'assignmentContainer',
		component: AssignmentContainer,
		meta: {
			hidden: true,
			title: 'Tehtävät',
			headline: 'Tehtävät',
			icon: 'mdi-heart',
		},
	},
	{
		path: '/companies',
		name: 'companyContainer',
		component: CompanyContainer,
		meta: {
			hidden: true,
			title: '4H-yritykset',
			headline: '4H-yritykset',
			icon: 'mdi-briefcase',
		},
		children: [
			{
				path: 'addnew',
				name: 'companyAddNew',
				component: CompanyAddNew,
				meta: {
					title: 'Perusta uusi 4H-yritys',
					headline: 'Perusta uusi 4H-yritys',
				},
			},
			{
				path: ':pagename',
				name: 'company',
				component: Company,
				props: true,
				meta: {
					title: '4H-yritys',
				},
				children: [
					{
						path: 'edit',
						name: 'companyEdit',
						component: CompanyEdit,
						meta: {
							title: 'Muokkaa 4H-yrityksen tietoja',
							headline: 'Muokkaa 4H-yrityksen tietoja',
						},
					},
					{
						path: 'participate',
						name: 'company-participation',
						component: CompanyParticipation,
						props: true,
						meta: {
							title: 'Osallistu 4H-yrityskilpailuun',
							headline: 'Osallistu 4H-yrityskilpailuun',
						},
					},
					{
						path: 'tracking',
						name: 'company-tracking',
						component: CompanyTracking,
						props: true,
						meta: {
							title: 'Seuranta',
							headline: 'Seuranta',
						},
					},
					{
						path: 'contest',
						name: 'company-contest',
						component: CompanyContest,
						props: true,
						meta: {
							title: 'Yrityskilpailu',
							headline: 'Yrityskilpailu',
						},
						children: [

						],
					},
					{
						path: 'study-credits',
						name: 'company-study-credits',
						component: StudyCredits,
						props: true,
						meta: {
							title: 'Opintopistehakemus',
							headline: 'Opintopistehakemus',
						},
						children: [

						],
					},
					{
						path: 'insurances',
						name: 'company-insurances',
						component: CompanyInsurances,
						props: true,
						meta: {
							title: 'Yritysvakuutus',
							headline: 'Yritysvakuutus',
						},
					},
					{
						path: 'invoices',
						name: 'company-invoices',
						component: CompanyInvoices,
						props: true,
						meta: {
							title: 'Laskut',
							headline: 'Laskut',
						},
						children: [
							{
								path: ':invoiceId',
								name: 'company-invoice',
								component: CompanyInvoice,
								props: true,
							},
						],
					},
				],
			},
		],
	},
	{
		name: 'Error404',
		path: '/error404',
		component: Error404,
		meta: {
			title: 'Sivua ei löytynyt',
			hidden: true,
		},
	},
	{
		path: '*',
		redirect: {
			name: 'Error404',
		},
		meta: {
			hidden: true,
		},
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

// Navigation guards
router.beforeEach((to, from, next) => {
	// If current route is not the first one the user visits,
	// we switch back button to the history mode.
	if (from.name !== null) {
		store.commit('setBackButtonHistoryMode', true)
	}

	// If navigation confirmation is requested, show dialog
	if (store.state.confirmNavigation) {
		store.commit('setConfirmNavigationDialog', true)
		store.commit('setConfirmNavigationRoute', to)
	} else {
		next()
	}
})

export default router
