<i18n>
{
  "en": {
		"headings": {
			"application": "Application"
		},
		"actions": {
			"logout": "Log out"
		}
	},
	"fi": {
		"headings": {
			"application": "Sovellus"
		},
		"actions": {
			"logout": "Kirjaudu ulos"
		}
	}
}
</i18n>

<template>
	<v-list
		dense
		nav
	>
		<!-- Link groups -->
		<template v-if="config.links && config.links.length">
			<v-list-item-group
				v-for="group in config.links"
				:key="group.id"
				color="primary"
			>
				<v-subheader class="text-uppercase">
					{{ group.title }}
				</v-subheader>
				<v-list-item
					v-for="item in group.items"
					:key="item.id"
					:href="item.url"
					target="_blank"
				>
					<v-list-item-icon v-if="item.icon">
						<v-icon>
							{{ item.icon }}
						</v-icon>
					</v-list-item-icon>
					<v-list-item-content v-if="item.label">
						{{ item.label }}
					</v-list-item-content>
				</v-list-item>
			</v-list-item-group>
		</template>

		<v-list-item-group
			color="primary"
		>
			<v-subheader class="text-uppercase">
				Sovellus
			</v-subheader>
			<v-list-item
				v-if="$router.options.routes.find(route => route.name == 'formPageContainer')"
				:to="({ name: 'formPageContainer' })"
			>
				<v-list-item-icon>
					<v-icon>mdi-pencil</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					Lomakkeet
				</v-list-item-content>
			</v-list-item>
			<v-list-item
				v-if="clubs && clubs.length && $router.options.routes.find(route => route.name == 'clubContainer')"
				:to="({ name: 'clubContainer' })"
			>
				<v-list-item-icon>
					<v-icon>mdi-heart</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					Kerhot
				</v-list-item-content>
			</v-list-item>

			<v-list-item
				v-if="user && user.assignment.length && $router.options.routes.find(route => route.name == 'assignmentContainer')"
				:to="({ name: 'assignmentContainer' })"
			>
				<v-list-item-icon>
					<v-icon>mdi-account-tie</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					Tehtävät
				</v-list-item-content>
			</v-list-item>

			<v-list-item
				v-if="user._is_member && $router.options.routes.find(route => route.name == 'companyContainer')"
				:to="({ name: 'companyContainer' })"
			>
				<v-list-item-icon>
					<v-icon>mdi-currency-eur</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					4H-yritykset
				</v-list-item-content>
			</v-list-item>
			<v-list-item
				v-if="$router.options.routes.find(route => route.name == 'settings')"
				:to="({ name: 'settings' })"
			>
				<v-list-item-icon>
					<v-icon>mdi-cog</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					Asetukset
				</v-list-item-content>
			</v-list-item>
			<v-list-item @click="$emit('logout')">
				<v-list-item-icon>
					<v-icon>mdi-logout</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					Kirjaudu ulos
				</v-list-item-content>
			</v-list-item>
		</v-list-item-group>
	</v-list>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'OffCanvasNav',
	computed: {
		...mapState([
			'config',
			'user',
			'clubs',
		]),
	},
}

</script>

<style lang="scss" scoped>

</style>
