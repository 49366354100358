<template>
	<div>
		<v-container class="container--narrow">
			<CompanyContestNotification v-if="company._available_contests && company._available_contests.length" />
			<FilterableList
				v-if="company.yrityskilpailu"
				:items="company.yrityskilpailu"
				:enable-search="false"
				icon-src="_list_icon"
				title-src="_list_title"
				summary-src="_list_summary"
				no-items-text="Yrityksesi ei ole osallistunut yhteenkään yrityskilpailuun."
				@itemClick="openParticipationDialog"
			/>

			<!-- Participation details dialog -->
			<v-dialog
				v-model="participationDialogOpen"
				max-width="500"
			>
				<v-card>
					<v-card-title>
						{{ participationSelected._list_title }}
					</v-card-title>
					<v-card-subtitle>
						{{ participationSelected._list_summary }}
					</v-card-subtitle>
					<v-divider />
					<v-card-text>
						<FilterableList
							:items="participationSelected._details"
							:enable-click="(false)"
							:enable-search="(false)"
						/>
					</v-card-text>
					<v-divider />
					<v-card-actions>
						<v-spacer />
						<v-btn
							color="primary"
							text
							@click="participationDialogOpen = false"
						>
							Sulje
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-container>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import CompanyContestNotification from '@/components/CompanyContestNotification'

export default {
	name: 'CompanyContest',

	components: {
		CompanyContestNotification,
	},

	props: {
		company: {
			type: Object,
			required: true,
		},
	},

	data: () => ({
		participationDialogOpen: false,
		participationSelected: {},
	}),

	computed: {
		...mapState({
			config: state => state.config,
			user: state => state.user,
			companies: state => state.companies,
		}),
	},

	mounted () {
	},

	methods: {
		openParticipationDialog (item) {
			this.participationSelected = item

			this.$nextTick(() => {
				this.participationDialogOpen = true
			})
		},
	},
}
</script>
