<template>
	<div>
		<v-container class="container--narrow">
			<FilterableList
				v-if="insurances && insurances.length"
				:items="insurances"
				:enable-search="false"
				icon-src="_list_icon"
				title-src="title"
				@itemClick="openDialog"
			>
				<!-- <template #subtitle="slotProps">
					{{ slotProps.item.enddate }}
				</template> -->
				<template #summary="slotProps">
					{{ slotProps.item._list_summary }}
				</template>
				<template #meta="slotProps">
					{{ formattedDate(slotProps.item.startdate) }}
				</template>
			</FilterableList>

			<v-alert
				v-else
				type="info"
			>
				Sinulla ei ole 4H-yritysvakuutusta.
			</v-alert>

			<template v-if="(activeInsurances.length === 0)">
				<v-btn
					color="primary"
					@click="openDialog(null)"
				>
					<v-icon left>
						mdi-file-sign
					</v-icon>
					Tilaa uusi 4H-yritysvakuutus
				</v-btn>
			</template>

			<!-- Add insurance -->
			<v-dialog
				v-model="dialogOpen"
				max-width="500"
				persistent
				ref="insuranceDialog"
			>
				<v-card>
					<v-card-title>
						<template v-if="formData.id">
							Vakuutustiedot
						</template>
						<template v-else>
							Tilaa vakuutus
						</template>
					</v-card-title>
					<v-divider />
					<template v-if="formLoading === true">
						<v-card-text>
							<Spinner
								:text="formLoadingText"
							/>
						</v-card-text>
					</template>
					<template v-else>
						<v-card-text>
							<template v-if="!formData.id">
								<p>Tilaa 4H-yritysvakuutus laskulla. Laskun maksaminen tapahtuu Svea Paymentin maksupalvelussa.</p>
							</template>
							<template v-else>
								<p>{{ formData.title }}</p>
								<p>
									{{ formattedDateRange(formData.startdate, formData.paid_to_date_modified) }}
								</p>
							</template>
							<SchemaToForm
								ref="insuranceForm"
								:debug="false"
								:schema="config.forms.companyInsurance"
								:disabled="formLoading"
								v-model="formData"
								@valid="formValid = true"
								@invalid="formValid = false"
							/>
						</v-card-text>
						<v-divider />
						<v-card-actions>
							<template v-if="formData.id">
								<v-btn
									text
									:disabled="formLoading"
									@click="dialogOpen = false"
								>
									Sulje lomake
								</v-btn>
								<v-spacer />
								<template v-if="(insurance._is_purchasable === true)">
									<v-btn
										color="success"
										text
										:to="{ name: 'company-invoice', params: { invoiceId: insurance._invoice_ref_id } }"
									>
										Maksa lasku
									</v-btn>
								</template>
								<template v-else-if="(insurance._is_endable === true)">
									<v-btn
										color="error"
										text
										:disabled="formLoading || !formValid"
										:loading="formLoading"
										@click="endInsurance"
									>
										Irtisano vakuutus
									</v-btn>
								</template>
							</template>
							<template v-else>
								<v-btn
									text
									:disabled="formLoading"
									@click="dialogOpen = false"
								>
									Sulje lomake
								</v-btn>
								<v-spacer />
								<v-btn
									color="success"
									text
									:disabled="formLoading || !formValid"
									:loading="formLoading"
									@click="createInsurance"
								>
									Tilaa vakuutus
								</v-btn>
							</template>
						</v-card-actions>
					</template>
				</v-card>
			</v-dialog>
		</v-container>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import SchemaToForm from '@/components/SchemaToForm'

export default {
	name: 'CompanyInsurance',

	components: {
		SchemaToForm,
	},

	props: {
		company: {
			type: Object,
			required: true,
		},
	},

	data: () => ({
		insurance: {},
		dialogOpen: false,
		formData: {},
		formValid: false,
		formLoading: false,
		formLoadingText: null,
		date: new Date(),
	}),

	computed: {
		...mapState({
			config: state => state.config,
		}),

		insurances () {
			return this.company.orders
		},

		activeInsurances () {
			return this.company.orders.filter((order) => {
				return order._is_endable === true
			})
		},
	},

	mounted () {
		this.resetForm()
	},

	methods: {
		formattedDate (date) {
			return new Date(date).toLocaleDateString('fi-FI')
		},

		formattedDateRange (from, to) {
			return (
				(
					from
						? new Date(from).toLocaleDateString('fi-FI')
						: ''
				)
				+ ' – ' +
				(
					to
						? new Date(to).toLocaleDateString('fi-FI')
						: ''
				)
			)
		},

		resetForm () {
			this.formData = {
				product_ref: this.company._productlist_product_ref ? this.company._productlist_product_ref.name : 845845,
				startdate: this.date.toLocaleDateString('fi-FI'),
			}
		},

		openDialog (item = null) {
			this.resetForm()

			if (item && item.id) {
				this.formData = Object.assign({}, item)
				this.insurance = item
			}

			this.dialogOpen = true

			this.$nextTick(() => {
				// Reset form validation
				this.$refs.insuranceForm.$children[0].resetValidation()

				// Scroll dialog to top
				document.getElementsByClassName('v-dialog v-dialog--active')[0].scrollTop = 0
			})
		},

		saveForm () {
			this.formLoading = true

			this.$api.Companies.doRequest({
				url: 'insurance',
				method: 'POST',
				body: {
					...this.formData,
					company_id: this.company.id,
				},
			}).on('done', res => {
				if (!res.body.error) {
					this.dialogOpen = false

					this.$nextTick(() => {
						this.$parent.loadCompany()
					})
				}
			}).on('finish', () => {
				this.formLoading = false
			})
		},

		//

		createInsurance () {
			this.formLoading = true
			this.formLoadingText = 'Tilataan vakuutusta...'

			this.$api.Companies.doRequest({
				url: 'insurance/create',
				method: 'POST',
				body: {
					...this.formData,
					company_id: this.company.id,
				},
			}).on('done', (res) => {
				this.formLoading = false
				this.dialogOpen = false

				// If invoice ID was returned, navigate to invoice page
				if (res.body && res.body.invoice_id) {
					this.$router.push({ name: 'company-invoice', params: { invoiceId: res.body.invoice_id } })
				}
			}).on('finish', () => {
				this.formLoading = false
			})
		},

		endInsurance () {
			this.formLoading = true
			this.formLoadingText = 'Irtisanotaan vakuutusta...'

			this.$api.Companies.doRequest({
				url: 'insurance/end',
				method: 'POST',
				body: {
					...this.formData,
					company_id: this.company.id,
				},
			}).on('done', () => {
				this.dialogOpen = false

				// we should invoke the method found in parent component via $parent or use bus emission
				this.$api.Companies.doRequest()

				this.$nextTick(() => {
					this.$store.dispatch('clearConfirmNavigation')
					this.$router.push({ name: 'companyEdit' })
				})
			}).on('finish', () => {
				this.formLoading = false
			})
		},
	},
}
</script>
