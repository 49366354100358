<template>
	<v-container class="container--narrow">
		<v-card v-if="ready">
			<v-card-text>
				<SchemaToForm
					:debug="false"
					:schema="formSchema"
					:disabled="saveLoading"
					v-model="formData"
					@valid="formValid = true"
					@invalid="formValid = false"
					@change="$store.commit('setConfirmNavigation', true)"
				/>
			</v-card-text>
			<v-divider />
			<v-card-actions>
				<v-spacer />
				<v-btn
					color="success"
					:loading="saveLoading"
					:disabled="saveLoading || !formValid"
					@click="save"
					text
				>
					Tallenna
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
import SchemaToForm from '@/components/SchemaToForm'
import { mapState } from 'vuex'

export default {
	name: 'CompanyEdit',
	components: {
		SchemaToForm,
	},
	data: () => ({
		ready: false,
		item: null,
		saveLoading: false,
		formSchema: {},
		formData: {},
		formValid: false,
	}),
	computed: {
		...mapState({
			config: state => state.config,
			user: state => state.user,
			companies: state => state.companies,
		}),
	},
	mounted () {
		this.loadItem()

		// Get schema
		const formSchema = JSON.parse(JSON.stringify(this.config.forms.company))

		// Some fields are disabled or hidden when editing existing company
		formSchema.fields.find(item => item.name === 'c_perustamisvuosi').props.disabled = true
		formSchema.fields.find(item => item.name === 'c_sitoutuu_4hkriteereihin').hidden = true

		// Disable end date if it has value
		if (this.item.c_yritystoiminta_paattynyt && this.item.c_yritystoiminta_paattynyt.length) {
			formSchema.fields.find(item => item.name === 'c_yritystoiminta_paattynyt').props.disabled = true
		}

		// Set schema
		this.formSchema = formSchema

		this.$nextTick(() => {
			this.ready = true
		})
	},
	methods: {
		// Load current company details from the store
		loadItem () {
			this.item = this.companies.find(item => {
				return item.name == this.$route.params.pagename
			})

			// Set form data
			this.formData = this.item

			if (!this.item) {
				this.$router.replace({ name: 'Error404' })
			}
		},

		save () {
			this.saveLoading = true

			this.$api.Companies.doRequest({
				method: 'POST',
				body: {
					...this.formData,
					id: this.item.id,
				},
			}).on('done', res => {
				if (!res.body.error) {
					this.$nextTick(() => {
						this.$store.dispatch('clearConfirmNavigation')
						this.$router.replace({
							name: this.$route.matched[this.$route.matched.length - 2].name,
						})
					})
				}
			}).on('finish', () => {
				this.saveLoading = false
			})
		},
	},
}
</script>
