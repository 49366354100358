<template>
	<v-alert
		type="info"
		prominent
	>
		<v-row align="center">
			<v-col class="grow">
				4H-yrityskilpailu on käynnissä!
			</v-col>
			<v-col class="shrink">
				<v-btn
					text
					:to="{ name: 'company-participation'}"
				>
					<v-icon left>
						mdi-star
					</v-icon>
					Osallistu
				</v-btn>
			</v-col>
		</v-row>
	</v-alert>
</template>

<script>

export default {
	name: 'CompanyContestNotification',
}

</script>

