<template>
	<div>
		<template v-if="loading">
			Ladataan...
		</template>
		<template v-else-if="error">
			<p>{{ error }}</p>
		</template>
		<template v-else>
			<v-container class="container--narrow">
				<v-card v-if="config.forms && config.forms.myAccount">
					<v-card-text>
						<SchemaToForm
							v-if="formData && Object.keys(formData).length"
							:debug="false"
							:schema="formSchema"
							:disabled="saveLoading"
							v-model="formData"
							@valid="formValid = true"
							@invalid="formValid = false"
							@change="$store.commit('setConfirmNavigation', true)"
						/>
					</v-card-text>
					<v-divider />
					<v-card-actions>
						<v-spacer />
						<v-btn
							color="success"
							:loading="saveLoading"
							:disabled="saveLoading || !formValid"
							@click="save(true)"
							text
						>
							Tallenna
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-container>
			<v-container
				v-if="allowDelete === true"
				class="container--narrow"
			>
				<v-expansion-panels>
					<v-expansion-panel>
						<v-expansion-panel-header>
							<div>
								<v-icon color="error">
									mdi-delete-forever
								</v-icon>
								<span class="pl-3">Erota yksittäinen jäsen</span>
							</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-alert
								dense
								type="warning"
								border="left"
							>
								Huomaathan, että tietojen palauttaminen ei ole mahdollista!
							</v-alert>
							<v-btn
								color="error"
								:loading="saveLoading"
								:disabled="saveLoading"
								@click="deleteMember(true)"
							>
								<v-icon left>
									mdi-delete-forever
								</v-icon>
								Vahvista jäsenen erottaminen
							</v-btn>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-container>
		</template>

		<!-- Confirm unsubscribing membership -->
		<v-dialog
			v-model="confirmUnsubscribeDialog"
			max-width="300"
		>
			<v-card>
				<v-card-title class="headline justify-center">
					Vahvista eroaminen
				</v-card-title>
				<v-divider />
				<v-card-text class="text-center">
					Haluatko varmasti erota jäsenyydestä?
				</v-card-text>
				<v-divider />
				<v-card-actions class="justify-center">
					<v-btn
						color="success"
						text
						@click="confirmUnsubscribeDialog = false"
					>
						Peruuta
					</v-btn>
					<v-btn
						color="error"
						text
						@click="save(false)"
					>
						Kyllä
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Confirm member deletion -->
		<v-dialog
			v-model="confirmDeleteDialog"
			max-width="300"
		>
			<v-card>
				<v-card-title class="headline justify-center">
					Vahvista eroaminen
				</v-card-title>
				<v-card-text class="text-center">
					Tietojen palauttaminen ei ole mahdollista!
				</v-card-text>
				<v-divider />
				<v-card-actions class="justify-center">
					<v-btn
						color="success"
						text
						@click="confirmDeleteDialog = false"
					>
						Peruuta
					</v-btn>
					<v-btn
						color="error"
						text
						@click="deleteMember(false)"
					>
						Kyllä
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>

import { mapState } from 'vuex'
import SchemaToForm from '@/components/SchemaToForm'

export default {
	name: 'MyAccount',
	components: {
		SchemaToForm,
	},
	data: () => ({
		formSchema: {},
		formData: {},
		loading: true,
		saveLoading: false,
		formValid: false,
		error: '',
		confirmUnsubscribeDialog: false,
		confirmDeleteDialog: false,
		allowDelete: false,
	}),
	computed: {
		...mapState([
			'user',
			'config',
		]),
	},
	mounted () {
		try {
			// Get form schema
			const formSchema = JSON.parse(JSON.stringify(this.config.forms.myAccount))

			if (this.user.id == this.$route.params.id) {
				this.formData = JSON.parse(JSON.stringify(this.user))

				// Test different user types here
				// this.formData._is_parent = true
				// this.formData._is_member = true
				// this.formData._has_membership = true

				// Modify schema for parents
				if (this.formData._is_parent) {
					let streetAddressField = formSchema.fields.find(field => field.name == 'street_address')

					if (streetAddressField) {
						(streetAddressField.rules = streetAddressField.rules || []).push('required')
						streetAddressField.showIf = null
						streetAddressField.requiredIf = null
					}

					let postalField = formSchema.fields.find(field => field.name == 'postal_code')

					if (postalField) {
						(postalField.rules = postalField.rules || []).push('required')
						postalField.showIf = null
						postalField.requiredIf = null
					}

					let cityField = formSchema.fields.find(field => field.name == 'city')

					if (cityField) {
						(cityField.rules = cityField.rules || []).push('required')
						cityField.showIf = null
						cityField.requiredIf = null
					}
				}
			} else {
				let child = this.user._children.find(item => item.id == this.$route.params.id)

				if (child) {
					this.formData = JSON.parse(JSON.stringify(child))

					// Since member being edited is child, allow deleting
					this.allowDelete = (this.user._is_parent && child._has_parent === true && child._has_membership === false)
				} else {
					throw 'Jäsentä ei löytynyt!'
				}
			}

			// Apply form schema
			this.formSchema = formSchema

			// Set custom headline
			this.$root.$emit('setHeadline', [
				this.formData.firstname,
				this.formData.lastname,
			].join(' '))
		} catch (error) {
			this.error = error
		} finally {
			this.loading = false
		}
	},
	methods: {
		save (confirm = true) {
			if (confirm === true && this.formData.c_haluan_erota_jasenyydesta) {
				this.confirmUnsubscribeDialog = true

				return
			}

			this.confirmUnsubscribeDialog = false
			this.saveLoading = true

			this.$api.Me.doRequest({
				url: 'edit',
				method: 'POST',
				body: {
					id: this.$route.params.id,
					...this.formData,
				},
			}).on('done', res => {
				// Gtag event
				this.$gtag.event('save', {
					'event_category': 'form',
					'event_label': 'my account',
					'value': null,
				})

				if (!res.body.error) {
					// Clear navigation confirm
					this.$nextTick(() => {
						this.$store.dispatch('clearConfirmNavigation')

						// Navigate to parent route
						this.$router.replace({
							name: this.$route.matched[this.$route.matched.length - 2].name,
						})
					})
				}
			}).on('finish', () => {
				this.saveLoading = false
			})
		},
		async deleteMember (confirm = true) {
			if (confirm === true) {
				this.confirmDeleteDialog = true

				return
			}

			this.confirmDeleteDialog = false
			this.saveLoading = true

			this.$api.Me.doRequest({
				url: 'delete',
				method: 'POST',
				body: {
					id: this.$route.params.id,
				},
			}).on('done', res => {
				if (!res.body.error){
					// Clear navigation confirm
					this.$nextTick(() => {
						this.$store.dispatch('clearConfirmNavigation')

						// Navigate to parent route
						this.$router.replace({
							name: this.$route.matched[this.$route.matched.length - 2].name,
						})
					})
				}
			}).on('finish', () => {
				this.saveLoading = false
			})
		},
	},
}
</script>
