<template>
	<div>
		<v-toolbar
			v-if="items.association.length"
			color="primary"
			dark
			flat
		>
			<v-tabs
				v-model="currentTab"
				centered
				center-active
				show-arrows
			>
				<v-tabs-slider />

				<v-tab
					v-for="item in tabs"
					:key="item.name"
				>
					{{ item.title }}
				</v-tab>
			</v-tabs>
		</v-toolbar>
		<v-tabs-items v-model="currentTab">
			<!-- General -->
			<v-tab-item>
				<v-container class="container--narrow">
					<FilterableList
						v-if="items.general && items.general.length"
						:items="items.general"
						@itemClick="itemClick"
					/>
					<v-alert
						v-else
						type="info"
					>
						Ei uutisia
					</v-alert>
				</v-container>
			</v-tab-item>

			<!-- Association -->
			<v-tab-item>
				<v-container class="container--narrow">
					<FilterableList
						v-if="items.association && items.association.length"
						:items="items.association"
						@itemClick="itemClick"
					/>
					<v-alert
						v-else
						type="info"
					>
						Ei uutisia
					</v-alert>
				</v-container>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>

import { mapState } from 'vuex'
import FilterableList from '@/components/FilterableList'

export default {
	name: 'NewsContainer',
	components: {
		FilterableList,
	},
	data: () => ({
		currentTab: 0,
		tabs: [
			{
				name: 'general',
				title: 'Liitto',
			},
			{
				name: 'association',
				title: 'Oma yhdistys',
			},
		],
	}),
	computed: {
		...mapState({
			items: state => state.news,
		}),
	},
	mounted () {
		this.$api.News.doRequest()
	},
	methods: {
		itemClick (item) {
			this.$router.push({ name: 'news', params: { pagename: item.name } })
		},
	},
}
</script>
