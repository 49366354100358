<template>
	<div v-if="item">
		<v-toolbar
			color="primary"
			dark
			flat
		>
			<v-tabs
				v-model="currentTab"
				centered
				center-active
				show-arrows
			>
				<v-tabs-slider />

				<v-tab
					v-for="tab in tabs"
					:key="tab.name"
				>
					{{ tab.title }}
				</v-tab>
			</v-tabs>
		</v-toolbar>

		<v-tabs-items v-model="currentTab">
			<!-- Details -->
			<v-tab-item>
				<v-container class="container--narrow">
					<v-card>
						<v-card-title>
							Kerhon tiedot
						</v-card-title>
						<v-divider />
						<v-card-text>
							<FilterableList
								:items="item.details"
								:enable-click="(false)"
								:enable-search="(false)"
								class="mb-4"
							/>
							<SchemaToForm
								ref="clubForm"
								:debug="false"
								:schema="config.forms.club"
								:disabled="clubSaveLoading"
								v-model="clubFormData"
								@valid="clubFormValid = true"
								@invalid="clubFormValid = false"
								@change="$store.commit('setConfirmNavigation', true)"
							/>
						</v-card-text>
						<v-divider />
						<v-card-actions>
							<v-spacer />
							<v-btn
								color="success"
								text
								:disabled="clubSaveLoading || !clubFormValid"
								:loading="clubSaveLoading"
								@click="saveClub"
							>
								Tallenna
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-container>
				<v-container
					class="container--narrow"
				>
					<v-card>
						<v-card-title>
							Vuoden 4H-kerho -kilpailut
						</v-card-title>
						<v-divider />
						<v-card-text>
							<FilterableList
								:items="item.kerhot_kilpailu || []"
								title-src="_list_title"
								summary-src="_list_summary"
								icon-src="_list_icon"
								:enable-search="false"
								no-items-text="Kerho ei ole osallistunut vuoden 4H-kerho -kilpailuihin."
								@itemClick="openParticipationDialog"
							/>
						</v-card-text>
						<v-divider />
						<v-card-actions>
							<v-btn
								text
								color="primary"
								@click="openParticipationDialog"
							>
								<v-icon left>
									mdi-plus-circle
								</v-icon>
								Lisää uusi osallistuminen
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-container>
			</v-tab-item>

			<!-- Visits -->
			<v-tab-item>
				<v-container class="container--narrow">
					<FilterableList
						:items="item.c_kerhot || []"
						title-src="c_kerhokaynti_tarkenne"
						summary-src="date"
						:meta-src="(null)"
						:enable-search="(false)"
						no-items-text="Ei kerhokäyntejä"
						@itemClick="openVisitDialog"
					/>
					<v-btn
						color="success"
						@click="openVisitDialog(null)"
						:disabled="clubSaveLoading"
					>
						<v-icon
							left
						>
							mdi-calendar-plus
						</v-icon>
						Lisää uusi kerhokäynti
					</v-btn>
				</v-container>
			</v-tab-item>

			<!-- Plans -->
			<v-tab-item>
				<v-container class="container--narrow">
					<FilterableList
						:items="item.c_kerhot_toimintasuunnitelma || []"
						title-src="c_kerhokaynti_tarkenne"
						summary-src="date"
						:meta-src="(null)"
						:enable-search="(false)"
						no-items-text="Ei toimintasuunnitelmia"
						@itemClick="openPlanDialog"
					/>
					<v-btn
						color="success"
						@click="openPlanDialog(null)"
						:disabled="planSaveLoading"
					>
						<v-icon
							left
						>
							mdi-calendar-plus
						</v-icon>
						Lisää uusi toimintasuunnitelma
					</v-btn>
				</v-container>
			</v-tab-item>
		</v-tabs-items>

		<!-- Add visit dialog -->
		<v-dialog
			v-model="visitDialogOpen"
			max-width="500"
			persistent
		>
			<v-card>
				<v-card-title>
					<template v-if="visitFormData.id">
						Muokkaa kerhokäyntiä
					</template>
					<template v-else>
						Uusi kerhokäynti
					</template>
				</v-card-title>
				<v-divider />
				<v-card-text>
					<SchemaToForm
						ref="visitForm"
						:debug="false"
						:schema="config.forms.clubEvent"
						:disabled="visitSaveLoading"
						v-model="visitFormData"
						@valid="visitFormValid = true"
						@invalid="visitFormValid = false"
					/>
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-btn
						color="error"
						text
						:disabled="visitSaveLoading"
						@click="visitDialogOpen = false"
					>
						Peruuta
					</v-btn>
					<v-spacer />
					<v-btn
						color="success"
						text
						:disabled="visitSaveLoading || !visitFormValid"
						:loading="visitSaveLoading"
						@click="saveVisit"
					>
						Tallenna
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Add plan dialog -->
		<v-dialog
			v-model="planDialogOpen"
			max-width="500"
			persistent
		>
			<v-card>
				<v-card-title>
					<template v-if="planFormData.id">
						Muokkaa toimintasuunnitelmaa
					</template>
					<template v-else>
						Uusi toimintasuunnitelma
					</template>
				</v-card-title>
				<v-divider />
				<v-card-text>
					<SchemaToForm
						ref="planForm"
						:debug="false"
						:schema="config.forms.clubPlan"
						:disabled="planSaveLoading"
						v-model="planFormData"
						@valid="planFormValid = true"
						@invalid="planFormValid = false"
					/>
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-btn
						color="error"
						text
						:disabled="planSaveLoading"
						@click="planDialogOpen = false"
					>
						Peruuta
					</v-btn>
					<v-spacer />
					<v-btn
						color="success"
						text
						:disabled="planSaveLoading || !planFormValid"
						:loading="planSaveLoading"
						@click="savePlan"
					>
						Tallenna
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Participate to the contest -->
		<v-dialog
			v-model="participationDialogOpen"
			max-width="400"
			persistent
		>
			<v-card>
				<v-card-title>
					<template v-if="participationFormData.id">
						Muokkaa osallistumista
					</template>
					<template v-else>
						Uusi osallistuminen
					</template>
				</v-card-title>
				<v-divider />
				<v-card-text>
					<SchemaToForm
						ref="participationForm"
						:debug="false"
						:schema="config.forms.clubParticipation"
						:disabled="participationSaveLoading"
						v-model="participationFormData"
						@valid="participationFormValid = true"
						@invalid="participationFormValid = false"
					/>
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-btn
						text
						:disabled="participationSaveLoading"
						@click="participationDialogOpen = false"
					>
						Peruuta
					</v-btn>
					<v-spacer />
					<v-btn
						color="success"
						text
						:disabled="participationSaveLoading || !participationFormValid"
						:loading="participationSaveLoading"
						@click="saveParticipation"
					>
						Tallenna
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>

import { mapState } from 'vuex'
import FilterableList from '@/components/FilterableList'
import SchemaToForm from '@/components/SchemaToForm'

export default {
	name: 'Club',
	components: {
		FilterableList,
		SchemaToForm,
	},
	data: () => ({
		loading: true,
		item: null,
		currentTab: 0,
		tabs: [
			{
				name: 'details',
				title: 'Tiedot',
			},
			{
				name: 'visits',
				title: 'Kerhokäynnit',
			},
			{
				name: 'plan',
				title: 'Toimintasuunnitelma',
			},
		],
		clubSaveLoading: false,
		clubFormData: {},
		clubFormValid: false,
		visitDialogOpen: false,
		visitSaveLoading: false,
		visitFormData: {},
		visitFormValid: false,
		planDialogOpen: false,
		planSaveLoading: false,
		planFormData: {},
		planFormValid: false,
		participationDialogOpen: false,
		participationSaveLoading: false,
		participationFormData: {},
		participationFormValid: false,
	}),
	computed: {
		...mapState({
			config: state => state.config,
			user: state => state.user,
			items: state => state.clubs,
		}),
	},
	mounted () {
		this.loadItem()

		// Set headline
		this.$root.$emit('setHeadline', this.item.c_kerhon_nimi)

		this.clubFormData = Object.assign(this.clubFormData, this.item)
		this.visitFormSchema = Object.assign({}, this.config.forms.clubEvent)

		// Set options for tutors
		let tutorField = this.visitFormSchema.fields.find(item => item.name == 'c_kerhokaynti_kerhonohjaajat')

		if (tutorField && this.item.c_kerhonohjaaja && this.item.c_kerhonohjaaja.length) {
			tutorField.props.items = this.item.c_kerhonohjaaja.map(item => {
				return {
					id: item.id,
					name: item.name,
					title: item.title,
				}
			})
		}

		this.loading = false
	},
	methods: {
		resetVisitForm () {
			this.visitFormData = {
				c_kerhokaynti_pvm: new Date().toISOString().substring(0, 10),
				c_kerhokaynti_kerhonohjaajat: [
					this.user.id,
				],
			}
		},

		resetPlanForm () {
			this.planFormData = {
				startdate: new Date().toISOString().substring(0, 10),
			}
		},

		openVisitDialog (item) {
			this.resetVisitForm()

			// If we are editing existing report, set form data
			if (item && item.id) {
				this.visitFormData = Object.assign({}, item)
			}

			this.visitDialogOpen = true

			this.$nextTick(() => {
				// Reset form validation
				this.$refs.visitForm.$children[0].resetValidation()

				// Scroll dialog to top
				document.getElementsByClassName('v-dialog v-dialog--active')[0].scrollTop = 0
			})
		},

		openPlanDialog (item) {
			this.resetPlanForm()

			// If we are editing existing report, set form data
			if (item && item.id) {
				this.planFormData = Object.assign({}, item)
			}

			this.planDialogOpen = true

			this.$nextTick(() => {
				// Reset form validation
				this.$refs.planForm.$children[0].resetValidation()

				// Scroll dialog to top
				document.getElementsByClassName('v-dialog v-dialog--active')[0].scrollTop = 0
			})
		},

		saveClub () {
			this.clubSaveLoading = true

			this.$api.Clubs.doRequest({
				method: 'POST',
				body: {
					id: this.item.id,
					...this.clubFormData,
				},
			}).on('done', res => {
				// Clear navigation confirm
				if (!res.body.error){
					this.$nextTick(() => {
						this.loadItem()
						this.$store.dispatch('clearConfirmNavigation')
					})
				}
			}).on('finish', () => {
				this.clubSaveLoading = false
			})
		},

		saveVisit () {
			this.visitSaveLoading = true

			this.$api.Clubs.doRequest({
				url: 'visit',
				method: 'POST',
				body: {
					...this.visitFormData,
					club_id: this.item.id,
				},
			}).on('done', res => {
				this.$nextTick(() => {
					this.loadItem()
				})

				if (!res.body.error) {
					this.visitDialogOpen = false
				}
			}).on('finish', () => {
				this.visitSaveLoading = false
			})
		},

		savePlan () {
			this.planSaveLoading = true

			this.$api.Clubs.doRequest({
				url: 'plan',
				method: 'POST',
				body: {
					...this.planFormData,
					club_id: this.item.id,
				},
			}).on('done', res => {
				this.$nextTick(() => {
					this.loadItem()
				})

				if (!res.body.error) {
					this.planDialogOpen = false
				}
			}).on('finish', () => {
				this.planSaveLoading = false
			})
		},

		resetParticipationForm () {
			this.participationFormData = {
				c_vuosi: new Date().getFullYear(),
			}
		},

		openParticipationDialog (item = {}) {
			this.resetParticipationForm()

			// If we are editing existing report, set form data
			if (item && item.id) {
				this.participationFormData = Object.assign({}, item)
			}

			this.participationDialogOpen = true

			this.$nextTick(() => {
				// Reset form validation
				this.$refs.participationForm.$children[0].resetValidation()

				// Scroll dialog to top
				document.getElementsByClassName('v-dialog v-dialog--active')[0].scrollTop = 0
			})
		},

		saveParticipation () {
			this.participationSaveLoading = true

			this.$api.Clubs.doRequest({
				url: 'participate',
				method: 'POST',
				body: {
					...this.participationFormData,
					club_id: this.item.id,
				},
			}).on('done', res => {
				this.$nextTick(() => {
					this.loadItem()
				})

				if (!res.body.error) {
					this.participationDialogOpen = false
				}
			}).on('finish', () => {
				this.participationSaveLoading = false
			})
		},

		// Load current club details from the store
		loadItem () {
			this.item = this.items.find(item => {
				return item.name == this.$route.params.pagename
			})

			if (!this.item) {
				this.$router.replace({ name: 'Error404' })
			}
		},
	},
}
</script>
