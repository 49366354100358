<i18n>
{
  "en": {
		"noItemsMessage": "No invoices to show.",
		"openInvoice": "Open invoice"
	},
  "fi": {
		"noItemsMessage": "Laskuja ei löytynyt.",
		"openInvoice": "Avoin lasku"
	}
}
</i18n>

<template>
	<div>
		<template v-if="($route.name === 'company-invoices')">
			<v-container class="container--narrow">
				<FilterableList
					v-if="company.invoice && company.invoice.length"
					:items="company.invoice"

					title-src="_list_title"
					summary-src="_list_summary"
					icon-src="_icon"
					icon-color-src="_icon_color"

					:enable-search="false"
					:multiline="true"
					@itemClick="(invoice) => $router.push({ name: 'company-invoice', params: { invoiceId: invoice.id } })"
				>
					<template
						#subtitle="slotProps"
					>
						{{ slotProps.item.id }}
					</template>
					<template
						#meta="slotProps"
						:item="invoice"
					>
						<v-chip
							v-if="slotProps.item._status && Object.keys(slotProps.item._status).length"
							:color="slotProps.item._status.color"
							outlined
							label
						>
							<v-icon
								v-if="slotProps.item._status.icon"
								left
							>
								{{ slotProps.item._status.icon }}
							</v-icon>

							{{ slotProps.item._status.text }}
						</v-chip>
					</template>
				</FilterableList>
				<v-alert
					v-else
					type="info"
				>
					{{ $i18n.t('noItemsMessage') }}
				</v-alert>
			</v-container>
		</template>
		<!-- Output child page -->
		<router-view
			:key="$route.fullPath"
			:company="company"
		/>
	</div>
</template>

<script>
import FilterableList from '@/components/FilterableList'

export default {
	name: 'CompanyInvoices',

	components: {
		FilterableList,
	},

	props: {
		company: {
			type: Object,
			required: true,
		},
	},

	data: () => ({
	}),

	computed: {

	},

	mounted () {
	},

	methods: {
	},
}
</script>
