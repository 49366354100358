<template>
	<div>
		<!-- Output child page -->
		<router-view />

		<!-- Output navigation if we're not on child page -->
		<template v-if="$route.name == 'memberDetails'">
			<Spinner
				v-if="loading"
				class="pa-12"
				text="Ladataan jäsentietoja"
			/>
			<template v-else>
				<v-container class="container--narrow">
					<v-card>
						<v-card-title>
							Omat tiedot
						</v-card-title>
						<v-divider />
						<FilterableList
							:items="[user]"
							:enable-search="(false)"
							icon-src="_icon"
							@itemClick="itemClick"
						/>
					</v-card>
				</v-container>
				<v-container
					v-if="user._is_parent === true"
					class="container--narrow"
				>
					<v-card>
						<v-card-title>
							Perheenjäsenet
						</v-card-title>
						<v-card-subtitle>
							Perheenjäsenen jäsenkortin käyttöön tarvitaan henkilökohtainen matkapuhelinnumero tai sähköpostiosoite.
						</v-card-subtitle>
						<v-divider />
						<FilterableList
							v-if="user._children && user._children.length"
							:items="user._children"
							:enable-search="(false)"
							icon-src="_icon"
							@itemClick="itemClick"
						/>
						<v-card-text v-else>
							<v-alert
								dense
								type="info"
							>
								Käyttäjätiliin ei ole liitetty perheenjäseniä.
							</v-alert>
						</v-card-text>
						<template v-if="this.user._has_family_membership === true">
							<v-divider />
							<v-card-actions>
								<v-spacer />
								<v-btn
									color="success"
									text
									:to="({ name: 'addMember' })"
								>
									Lisää uusi perheenjäsen
								</v-btn>
							</v-card-actions>
						</template>
					</v-card>
				</v-container>
			</template>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'
import FilterableList from '@/components/FilterableList'

export default {
	name: 'MemberDetails',
	components: {
		FilterableList,
	},
	data: () => ({
		loading: false,
	}),
	computed: {
		...mapState([
			'user',
		]),
	},
	methods: {
		itemClick (item) {
			this.$router.push({ name: 'myAccount', params: { id: item.id } })
		},
	},
}
</script>
