<template>
	<div>
		<!-- Output navigation if we're not on child page -->
		<template v-if="$route.name == 'assignmentContainer'">
			<v-container class="container--narrow">
				<FilterableList
					v-if="items && items.length"
					:items="items"
					icon-src="_list_icon"
					title-src="_list_title"
					subtitle-src="_list_subtitle"
					summary-src="_list_summary"
					redirect-url-src="_c_volunteer_contract_url"
					:enable-search="(false)"
					:enable-click="(false)"
				/>
				<v-alert
					v-else
					type="info"
				>
					Ei tehtäviä
				</v-alert>
			</v-container>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'AssignmentContainer',
	computed: {
		...mapState({
			items: state => state.user.assignment,
		}),
	},
}
</script>
