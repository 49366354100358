<template>
	<div>
		<v-container class="container--narrow">
			<FilterableList
				v-if="company.c_seuranta && company.c_seuranta.length"
				:items="company.c_seuranta"
				:enable-search="false"
				icon-src="_icon"
				title-src="c_perustamisvuosi"
				summary-src="_summary"
				meta-src="_c_seuranta_raportointipvm_formatted"
				@itemClick="openReportDialog"
			/>
			<v-alert
				v-else
				type="info"
			>
				Ei raportteja.
			</v-alert>
			<v-btn
				color="primary"
				@click="openReportDialog(null)"
			>
				<v-icon left>
					mdi-finance
				</v-icon>
				Lisää uusi raportti
			</v-btn>
			<!-- Add report -->
			<v-dialog
				v-model="reportDialogOpen"
				max-width="500"
				persistent
				ref="reportDialog"
			>
				<v-card>
					<v-card-title>
						<template v-if="reportFormData.id">
							Muokkaa raporttia
						</template>
						<template v-else>
							Uusi raportti
						</template>
					</v-card-title>
					<v-divider />
					<v-card-text>
						<SchemaToForm
							ref="reportForm"
							:debug="false"
							:schema="config.forms.companyReport"
							:disabled="reportSaveLoading"
							v-model="reportFormData"
							@valid="reportFormValid = true"
							@invalid="reportFormValid = false"
						/>
					</v-card-text>
					<v-divider />
					<v-card-actions>
						<v-btn
							color="error"
							text
							:disabled="reportSaveLoading"
							@click="reportDialogOpen = false"
						>
							Peruuta
						</v-btn>
						<v-spacer />
						<v-btn
							color="success"
							text
							:disabled="reportSaveLoading || !reportFormValid"
							:loading="reportSaveLoading"
							@click="saveReport"
						>
							Tallenna
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-container>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import SchemaToForm from '@/components/SchemaToForm'

export default {
	name: 'CompanyTracking',

	components: {
		SchemaToForm,
	},

	props: {
		company: {
			type: Object,
			required: true,
		},
	},

	data: () => ({
		reportDialogOpen: false,
		reportFormData: {},
		reportFormValid: false,
		reportSaveLoading: false,
	}),

	computed: {
		...mapState({
			config: state => state.config,
		}),
	},

	mounted () {
		this.resetReportForm()
	},

	methods: {
		resetReportForm () {
			this.reportFormData = {
				c_perustamisvuosi: new Date().getFullYear(),
			}
		},

		openReportDialog (item = null) {
			this.resetReportForm()

			// If we are editing existing report, set form data
			if (item && item.id) {
				this.reportFormData = Object.assign({}, item)
			}

			this.reportDialogOpen = true

			this.$nextTick(() => {
				// Reset form validation
				this.$refs.reportForm.$children[0].resetValidation()

				// Scroll dialog to top
				document.getElementsByClassName('v-dialog v-dialog--active')[0].scrollTop = 0
			})
		},

		saveReport () {
			this.reportSaveLoading = true

			this.$api.Companies.doRequest({
				url: 'report',
				method: 'POST',
				body: {
					...this.reportFormData,
					company_id: this.company.id,
				},
			}).on('done', res => {
				if (!res.body.error) {
					this.reportDialogOpen = false

					this.$nextTick(() => {
						this.$parent.setCompany()
					})
				}
			}).on('finish', () => {
				this.reportSaveLoading = false
			})
		},
	},
}
</script>
