import i18n from '@/i18n'

export default {
	required: v => (!!v) || i18n.t('validationErrors.required'),
	requiredAllowFalse: v => (!!v || v === false) || i18n.t('validationErrors.required'),
	email: v => (!v || /.+@.+/.test(v)) || i18n.t('validationErrors.email'),
	requiredFile: v => (!!v && typeof v === 'object') || i18n.t('validationErrors.required'),
	biggerThanZero: v => (!v || !v.length || parseInt(v) > 0) || 'Luvun on oltava 1 tai suurempi.',
	c_yritys_suunniteltu_liikevaihto: v => (!v || !v.length || (parseInt(v) >= 50 && parseInt(v) <= 8000)) || 'Luvun on oltava väliltä 50–8000.',
	sum: v => (!v || parseFloat(v.replace(',', '.')) >= 0) || 'Kentän arvon on oltava desimaaliluku',
}
