<template>
	<v-container class="container--narrow">
		<template v-if="ready">
			<v-card v-if="company._available_contests && company._available_contests.length">
				<v-card-text>
					<SchemaToForm
						:debug="false"
						:schema="config.forms.companyParticipation"
						:disabled="saveLoading"
						v-model="formData"
						@valid="formValid = true"
						@invalid="formValid = false"
						@change="$store.commit('setConfirmNavigation', true)"
					/>
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-spacer />
					<v-btn
						color="success"
						:loading="saveLoading"
						:disabled="saveLoading || !formValid"
						@click="confirmSaveDialogOpen = true"
						text
					>
						Tallenna
					</v-btn>
				</v-card-actions>
			</v-card>
			<v-alert
				v-else
				type="error"
			>
				Käynnissä olevia 4H-yrityskilpailuja ei löytynyt yrityksellesi.
			</v-alert>

			<!-- Confirm participation save -->
			<v-dialog
				v-model="confirmSaveDialogOpen"
				max-width="300"
				persistent
			>
				<v-card>
					<v-card-title>
						Vahvista tallennus
					</v-card-title>
					<v-divider />
					<v-card-text>
						Onko osallistumisesi valmis tallennettavaksi? Tietoja ei voi muuttaa jälkikäteen.
					</v-card-text>
					<v-divider />
					<v-card-actions>
						<v-btn
							color="error"
							text
							:disabled="saveLoading"
							@click="confirmSaveDialogOpen = false"
						>
							Peruuta
						</v-btn>
						<v-spacer />
						<v-btn
							color="success"
							text
							:disabled="saveLoading"
							:loading="saveLoading"
							@click="save()"
						>
							Tallenna
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</template>
	</v-container>
</template>

<script>

import SchemaToForm from '@/components/SchemaToForm'
import { mapState } from 'vuex'

export default {
	name: 'CompanyParticipation',
	components: {
		SchemaToForm,
	},
	props: {
		company: {
			type: [Object],
			required: true,
			default () {
				return null
			},
		},
	},
	data: () => ({
		ready: false,
		item: null,
		saveLoading: false,
		contestOptions: [],
		formSchema: {},
		formData: {},
		formValid: false,
		confirmSaveDialogOpen: false,
	}),
	computed: {
		...mapState({
			config: state => state.config,
		}),
	},
	mounted () {
		this.contestOptions = this.config.forms.companyParticipation.fields.find(item => item.name === 'c_yrityskilpailu_viittaus_liiton_asetuksiin_ref').props.items
		this.formData = {
			c_yrityskilpailu_viittaus_liiton_asetuksiin_ref: (this.contestOptions && this.contestOptions.length) ? this.contestOptions[0].id : null,
		}

		this.$nextTick(() => {
			this.ready = true
		})
	},
	methods: {
		save () {
			this.saveLoading = true

			this.$api.Companies.doRequest({
				url: 'participate',
				method: 'POST',
				body: {
					...this.formData,
					company_id: this.company.id,
				},
			}).on('done', res => {
				if (!res.body.error) {
					this.$store.dispatch('clearConfirmNavigation')
					this.confirmSaveDialogOpen = false
					this.$router.replace({
						name: this.$route.matched[this.$route.matched.length - 2].name,
					})
				}
			}).on('finish', () => {
				this.saveLoading = false
			})
		},
	},
}
</script>
