<template>
	<v-container
		v-if="ready"
		class="container--narrow"
	>
		<v-card>
			<v-card-text>
				<SchemaToForm
					:debug="false"
					:schema="formSchema"
					:disabled="saveLoading"
					v-model="formData"
					@valid="formValid = true"
					@invalid="formValid = false"
					@change="$store.commit('setConfirmNavigation', true)"
				/>
			</v-card-text>
			<v-divider />
			<v-card-actions>
				<v-spacer />
				<v-btn
					color="success"
					:loading="saveLoading"
					:disabled="saveLoading || !formValid"
					@click="save"
					text
				>
					Tallenna
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>

import SchemaToForm from '@/components/SchemaToForm'
import { mapState } from 'vuex'

export default {
	name: 'CompanyAddNew',
	components: {
		SchemaToForm,
	},
	data: () => ({
		ready: false,
		saveLoading: false,
		formSchema: {},
		formData: {
			c_perustamisvuosi: new Date().getFullYear(),
		},
		formValid: false,
	}),
	computed: {
		...mapState({
			config: state => state.config,
			user: state => state.user,
		}),
	},
	mounted () {
		// Get schema
		const formSchema = JSON.parse(JSON.stringify(this.config.forms.company))

		// Some fields are hidden when adding new company
		formSchema.fields.find(item => item.name === 'c_yritystoiminta_paattynyt').hidden = true

		// Set schema
		this.formSchema = formSchema

		this.$nextTick(() => {
			this.ready = true
		})
	},
	methods: {
		save () {
			this.saveLoading = true

			this.$api.Companies.doRequest({
				method: 'POST',
				body: {
					...this.formData,
					id: null,
				},
			}).on('done', res => {
				if (!res.body.error) {
					this.$nextTick(() => {
						this.$store.dispatch('clearConfirmNavigation')
						this.$router.replace({
							name: this.$route.matched[this.$route.matched.length - 2].name,
						})
					})
				}
			}).on('finish', () => {
				this.saveLoading = false
			})
		},
	},
}
</script>
