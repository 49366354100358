<template>
	<div>
		<v-container class="container--narrow">
			<FilterableList
				v-if="company.opintopistehakemukset && company.opintopistehakemukset.length"
				:items="company.opintopistehakemukset"
				:enable-search="false"
				icon-src="_icon"
				title-src="title"
				summary-src="_summary"
			/>
			<v-alert
				v-else
				type="info"
			>
				Ei opintopistehakemuksia.
			</v-alert>

			<v-alert>
				Ohjeita opintopisteiden hakemiseen löydät 4H-akatemian verkkosivuilta.
				<v-btn
					text
					color="secondary"
					href="https://www.4h-akatemia.fi/4H-yrittajalle"
					target="_blank"
				>
					Siirry 4h-akateamiaan.
				</v-btn>
			</v-alert>

			<v-alert
				v-if="anyUnprocessed"
				type="warning"
			>
				4H-yhdistys käsittelee hakemuksesi ja pyytää tarvittaessa täydennyksiä.
			</v-alert>
			<v-btn
				v-if="!anyUnprocessed"
				color="primary"
				@click="openstudyCreditsDialog(null)"
			>
				<v-icon left>
					mdi-account-school
				</v-icon>
				Lisää uusi opintopistehakemus
			</v-btn>
			<!-- Add studyCredits -->
			<v-dialog
				v-model="studyCreditsDialogOpen"
				max-width="500"
				persistent
				ref="studyCreditsDialog"
			>
				<v-card>
					<v-card-title>
						<template>
							Uusi opintopistehakemus
						</template>
					</v-card-title>
					<v-divider />
					<v-card-text>
						<SchemaToForm
							ref="studyCreditsForm"
							:debug="false"
							:schema="config.forms.studyCreditsApplication"
							:disabled="studyCreditsSaveLoading"
							v-model="studyCreditsFormData"
							@valid="studyCreditsFormValid = true"
							@invalid="studyCreditsFormValid = false"
						/>
					</v-card-text>
					<v-divider />
					<v-card-actions>
						<v-btn
							color="error"
							text
							:disabled="studyCreditsSaveLoading"
							@click="studyCreditsDialogOpen = false"
						>
							Peruuta
						</v-btn>
						<v-spacer />
						<v-btn
							color="success"
							text
							:disabled="studyCreditsSaveLoading || !studyCreditsFormValid"
							:loading="studyCreditsSaveLoading"
							@click="savestudyCredits"
						>
							Tallenna
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-container>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import SchemaToForm from '@/components/SchemaToForm'

export default {
	name: 'StudyCredits',

	components: {
		SchemaToForm,
	},

	props: {
		company: {
			type: Object,
			required: true,
		},
	},

	data: () => ({
		studyCreditsDialogOpen: false,
		studyCreditsFormData: {},
		studyCreditsFormValid: false,
		studyCreditsSaveLoading: false,
	}),

	computed: {
		...mapState({
			user: state => state.user,
			config: state => state.config,
		}),

		anyUnprocessed () {
			return this.company.opintopistehakemukset && this.company.opintopistehakemukset.some(item => !item.c_processed)
		},

	},

	mounted () {
		this.studyCreditsFormData = {
			lastname: this.user.lastname,
			firstname: this.user.firstname,
			birthdate: this.user.birthdate,
			email_address: this.user.email_address,
			gender: this.user.gender,
			c_yritys_nimi: this.company.c_yritys_nimi,
		}
	},

	methods: {

		openstudyCreditsDialog (item = null) {
			// If we are editing existing studyCredits, set form data
			if (item && item.id) {
				this.studyCreditsFormData = Object.assign({}, item)
			}

			this.studyCreditsDialogOpen = true

			this.$nextTick(() => {
				// Reset form validation
				this.$refs.studyCreditsForm.$children[0].resetValidation()

				// Scroll dialog to top
				document.getElementsByClassName('v-dialog v-dialog--active')[0].scrollTop = 0
			})
		},

		savestudyCredits () {
			this.studyCreditsSaveLoading = true

			this.$api.Companies.doRequest({
				url: 'study-credits',
				method: 'POST',
				body: {
					...this.studyCreditsFormData,
					company_id: this.company.id,
				},
			}).on('done', res => {
				if (!res.body.error) {
					this.studyCreditsDialogOpen = false

					this.$nextTick(() => {
						this.$parent.setCompany()
					})
				}
			}).on('finish', () => {
				this.studyCreditsSaveLoading = false
			})
		},
	},
}
</script>
