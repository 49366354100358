<template>
	<v-container
		v-if="ready === true"
		class="container--narrow"
	>
		<v-card v-if="config.forms && config.forms.memberAddNew">
			<v-card-text>
				<SchemaToForm
					:debug="false"
					:schema="formSchema"
					:disabled="saveLoading"
					v-model="formData"
					@valid="formValid = true"
					@invalid="formValid = false"
					@change="$store.commit('setConfirmNavigation', true)"
				/>
			</v-card-text>
			<v-divider />
			<v-card-actions>
				<v-spacer />
				<v-btn
					color="success"
					:loading="saveLoading"
					:disabled="saveLoading || !formValid"
					@click="save"
					text
				>
					Tallenna
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>

import { mapState } from 'vuex'
import SchemaToForm from '@/components/SchemaToForm'

export default {
	name: 'MemberAddNew',
	components: {
		SchemaToForm,
	},
	data: () => ({
		ready: false,
		formSchema: {},
		formData: {},
		saveLoading: false,
		formValid: false,
		error: '',
	}),
	computed: {
		...mapState([
			'user',
			'config',
		]),
	},
	mounted () {
		// Get form schema
		this.formSchema = JSON.parse(JSON.stringify(this.config.forms.memberAddNew))

		this.ready = true
	},
	methods: {
		async save () {
			this.saveLoading = true

			await this.$api.Me.doRequest({
				url: 'add',
				method: 'POST',
				body: {
					...this.formData,
				},
			}).on('done', res => {
				// Clear navigation confirm
				if (!res.body.error){
					this.$nextTick(() => {
						this.$store.dispatch('clearConfirmNavigation')

						// Navigate to parent route
						this.$router.replace({
							name: this.$route.matched[this.$route.matched.length - 2].name,
						})
					})
				}
			}).on('finish', () => {
				this.saveLoading = false
			})
		},
	},
}
</script>
