<template>
	<div>
		<!-- Output child page -->
		<router-view />

		<!-- Output navigation if we're not on child page -->
		<template v-if="$route.name == 'clubContainer'">
			<v-container class="container--narrow">
				<FilterableList
					v-if="items && items.length"
					:items="items"
					@itemClick="item => $router.push({ name: 'club', params: { pagename: item.name } })"
				/>
				<v-alert
					v-else
					type="info"
				>
					Ei kerhoja
				</v-alert>
			</v-container>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'ClubContainer',
	computed: {
		...mapState({
			items: state => state.clubs,
		}),
	},
	mounted () {
		this.$api.Clubs.doRequest()
	},
}
</script>
