<template>
	<div>
		<v-container
			class="container--narrow"
			v-if="($route.name == 'formPageContainer')"
		>
			<FilterableList
				v-if="items.length"
				:items="items"
				@itemClick="itemClick"
			/>
			<v-alert
				v-else
				type="info"
			>
				Ei lomakkeita
			</v-alert>
		</v-container>
		<router-view />
	</div>
</template>

<script>

import { mapState } from 'vuex'
import FilterableList from '@/components/FilterableList'

export default {
	name: 'FormPageContainer',
	components: {
		FilterableList,
	},
	computed: {
		...mapState({
			items: state => state.formPages,
		}),
	},
	mounted () {
		this.$api.FormPages.doRequest()
	},
	methods: {
		itemClick (item) {
			this.$router.push({
				name: 'form-item',
				params: {
					slug: item.name,
				},
			})
		},
	},
}
</script>
