<template>
	<v-container class="container--narrow">
		<template v-if="loading">
			<span>Ladataan...</span>
		</template>
		<template v-else-if="error">
			<p>{{ error }}</p>
		</template>
		<template v-else>
			<v-card>
				<v-card-text v-if="form.summary">
					<p>{{ form.summary }}</p>
				</v-card-text>
				<v-card-text>
					<form @submit.prevent="submit">
						<v-select
							required
							v-model="association"
							:hint="associations.label"
							:items="associations"
							item-text="title"
							item-value="id"
							:error-messages="associationErrors"
							no-data-text="Yhdistyksiä ei saatavilla."
							label="4H-yhdistys"
							@change="$v.association.$touch()"
							@blur="$v.association.$touch()"
						>
							<template #label>
								4H-yhdistys <strong class="red--text">*</strong>
							</template>
							<v-skeleton-loader
								class="mx-auto"
								max-width="300"
								type="text"
							/>
						</v-select>
						<v-select
							required
							v-model="assignment"
							:items="assignments"
							item-text="label"
							item-value="value"
							:error-messages="assignmentErrors"
							label="Tehtävä"
							@change="$v.assignment.$touch()"
							@blur="$v.assignment.$touch()"
						>
							<template #label>
								Tehtävä <strong class="red--text">*</strong>
							</template>
						</v-select>
						<v-textarea
							required
							v-model="information"
							:error-messages="informationErrors"
							label="Lisätietoja"
							hint="Kerro miksi kiinnostuit vapaaehtoistehtävästä, millaista osaamista sinulla ja millaista osaamista tarvitsisit lisää."
							persistent-hint
							rows="2"
							@input="$v.information.$touch()"
							@blur="$v.information.$touch()"
						>
							<template #label>
								Lisätietoja <strong class="red--text">*</strong>.
							</template>
						</v-textarea>
						<v-checkbox
							required
							v-model="confirmation"
							:error-messages="confirmationErrors"
							label="Haluan toimia vapaaehtoisena."
							@change="$v.confirmation.$touch()"
							@blur="$v.confirmation.$touch()"
						>
							<template #label>
								Haluan toimia vapaaehtoisena. <strong class="red--text">*</strong>
							</template>
						</v-checkbox>
						<p>
							<small>
								Annan luvan käyttää tietojani
								<v-tooltip bottom>
									<template #activator="{ on }">
										<a
											target="_blank"
											href="https://4h.fi/tietosuojaseloste/"
											@click.stop
											v-on="on"
										>
											tietosuojaselosteen
										</a>
									</template>
									Avautuu uuteen ikkunaan
								</v-tooltip>
								mukaisesti. Hyväksyn, että tarvittaessa rikostausta selvitetään 4H-yhdistyksen käytännön mukaisesti (laki Lasten kanssa toimivien rikostaustan selvittäminen).
							</small>
						</p>
						<v-btn
							type="submit"
							class="mt-4 mr-4"
							color="primary"
							:loading="formLoading"
							:disabled="formLoading"
						>
							<span>Lähetä lomake</span>
						</v-btn>
						<v-btn
							depressed
							class="mt-4"
							:loading="formLoading"
							:disabled="formLoading "
							@click="clear"
						>
							<span>Tyhjennä</span>
						</v-btn>
					</form>
				</v-card-text>
			</v-card>
		</template>
	</v-container>
</template>

<script>
import { mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
	name: 'FormItem',

	components: {
	},

	mixins: [validationMixin],

	validations: {
		association: { required },
		assignment: { required },
		information: { minLength: minLength(3) },
		confirmation: {
			checked (val) {
				return val
			},
		},
	},

	data () {
		return {
			loading: true,

			formLoading: false,
			formStatus: null,

			error: '',
			form: {},

			information: '',

			association: null,
			associations: [],

			assignment: null,
			assignments: [
				{
					label:'Aikuisohjaaja',
					value:'aikuisohjaaja',
				},
				{
					label:'Leirinohjaaja',
					value:'leirinohjaaja',
				},
				{
					label:'Muu ohjaaja tai kouluttaja',
					value:'muu-ohjaaja-tai-kouluttaja',
				},
				{
					label: 'Yritysohjaaja',
					value: 'yritysohjaaja',
				},
				{
					label:'Muu tehtävä',
					value:'muu-tehtava',
				},
			],

			confirmation: false,
		}
	},

	computed: {
		...mapState({
			forms: state => state.formPages,
			user: state => state.user,
		}),

		// form element errors

		associationErrors () {
			const errors = []
			if (!this.$v.association.$dirty) return errors
			!this.$v.association.required && errors.push('Valitse 4H-yhdistys.')
			return errors
		},

		assignmentErrors () {
			const errors = []
			if (!this.$v.assignment.$dirty) return errors
			!this.$v.assignment.required && errors.push('Valitse tehtävä.')
			return errors
		},

		informationErrors () {
			const errors = []
			if (!this.$v.information.$dirty) return errors
			!this.$v.information.required && errors.push('Kerro miksi kiinnostuit vapaaehtoistehtävästä, millaista osaamista sinulla ja millaista osaamista tarvitsisit lisää.')
			return errors
		},

		confirmationErrors () {
			const errors = []
			if (!this.$v.confirmation.$dirty) return errors
			!this.$v.confirmation.checked && errors.push('Hyväksy ilmoittautuminen.')
			return errors
		},
	},

	mounted () {
		try {
			this.form = this.forms.find(form => {
				return form.name == this.$route.params.slug
			})

			if (!this.form) throw 'Lomaketta ei löytynyt!'

			this.$root.$emit('setHeadline', this.form.title)

			this.association = this.user._association ? {
				id: this.user._association.id,
				title: this.user._association.title,
			} : null

		} catch (error) {
			this.error = error
		}

		this.getAssociations()
	},

	methods: {
		submit () {
			this.$v.$touch()
			if (this.$v.$invalid) {
				this.formStatus = 'error'
			} else {
				this.formStatus = 'pending'
				this.postForm()
			}
		},

		clear () {
			this.$v.$reset()
			this.association = null
			this.assignment = null
			this.information = ''
			this.confirmation = false
		},

		async getAssociations () {
			try {
				var response = await this.$api.Associations.doRequest()
				this.associations = response.result.body
			} catch (error) {
				// console.log('getAssociations() error:', error)
			}
			this.loading = false
		},

		async postForm () {
			this.formLoading = true

			try {
				await this.$api.FormItem.doRequest({
					method: 'POST',
					params: {
						slug: this.form.name,
					},
					body: {
						form_id: this.form.id,
						association: this.association.id ? this.association.id : this.association,
						assignment: [this.assignment],
						information: this.information,
						confirmation: this.confirmation,
					},
				}).on('done', (resp) => {
					if (!resp.body.error) {
						this.clear()
						this.formStatus = 'ok'
					}
				}).on('finish', () => {
					this.formLoading = false
				})
			} catch (error) {
				console.log('postForm() error:', error)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
</style>
