<template>
	<div>
		<!-- Membership card -->
		<v-container class="container--narrow">
			<div class="printLogo">
				<img
					class="printLogo__image"
					src="@/assets/logo.svg"
				>
			</div>
			<v-card>
				<v-img
					v-if="config._hero_image"
					class="white--text align-end"
					aspect-ratio="1.628"
					position="center top"
					:src="config._hero_image.url"
					gradient="rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .6) 100%"
					dark
				>
					<!-- aspect-ratio="1.628" -->
					<v-card-title class="headline">
						{{ user.firstname }} {{ user.lastname }}
					</v-card-title>
					<v-card-subtitle
						v-if="user._membership_title"
						class="white--text"
					>
						{{ user._membership_title }}
					</v-card-subtitle>
				</v-img>
				<template v-else>
					<v-card-title class="headline">
						{{ user.firstname }} {{ user.lastname }}
					</v-card-title>
					<v-card-subtitle v-if="user._membership_title">
						{{ user._membership_title }}
					</v-card-subtitle>
				</template>
				<v-card-text>
					<v-alert
						v-if="user._is_kunniapuheenjohtaja || user._is_kunniajasen"
						type="success"
					>
						Jäsenmaksusi on voimassa.
					</v-alert>
					<template v-else-if="user._is_member">
						<v-alert
							v-if="user._membership_payment_valid"
							type="success"
						>
							Jäsenmaksusi on voimassa.
						</v-alert>
						<v-alert
							v-else
							type="error"
						>
							Jäsenmaksusi ei ole voimassa!
						</v-alert>
					</template>
					<v-alert
						v-else
						type="error"
					>
						Tämän kortin haltija ei ole jäsen!
					</v-alert>
					<FilterableList
						:items="user._details"
						:enable-click="(false)"
						:enable-search="(false)"
					/>
				</v-card-text>
			</v-card>
		</v-container>

		<!-- Association details -->
		<v-container
			v-if="user._association && Object.keys(user._association).length"
			class="container--narrow"
		>
			<v-expansion-panels>
				<v-expansion-panel>
					<v-expansion-panel-header>
						<div>
							<v-icon color="primary">
								mdi-account-multiple
							</v-icon>
							<span class="pl-3">Näytä yhdistyksen tiedot</span>
						</div>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<h2
							v-if="user._association.title"
							class="text-h6 mb-6"
						>
							{{ user._association.title }}
						</h2>
						<p
							v-if="user._association._info"
							class="mb-6"
							v-html="user._association._info"
						/>
						<template
							v-if="user._association._directors && user._association._directors.length"
						>
							<h3 class="text-subtitle-2">
								<template v-if="user._association._directors.length > 1">
									Toiminnanjohtajat
								</template>
								<template v-else>
									Toiminnanjohtaja
								</template>
							</h3>
							<FilterableList
								:items="user._association._directors"
								:enable-click="(false)"
								:enable-search="(false)"
								title-src="_full_name"
								summary-src="_summary"
							>
								<template #meta="slotProps">
									<v-row
										no-gutters
									>
										<v-col>
											<v-btn
												v-if="slotProps.item._mobile_link"
												icon
												rounded
												:href="slotProps.item._mobile_link"
											>
												<v-icon color="primary">
													mdi-phone
												</v-icon>
											</v-btn>
										</v-col>
										<v-col>
											<v-btn
												v-if="slotProps.item._email_link"
												icon
												rounded
												:href="slotProps.item._email_link"
											>
												<v-icon color="primary">
													mdi-email
												</v-icon>
											</v-btn>
										</v-col>
									</v-row>
								</template>
							</FilterableList>
						</template>
						<template
							v-if="user._association._instructors && user._association._instructors.length"
						>
							<h3 class="text-subtitle-2">
								<template v-if="user._association._instructors.length > 1">
									Toiminnanohjaajat
								</template>
								<template v-else>
									Toiminnanohjaaja
								</template>
							</h3>
							<FilterableList
								:items="user._association._instructors"
								:enable-click="(false)"
								:enable-search="(false)"
								title-src="_full_name"
								summary-src="_summary"
							>
								<template #meta="slotProps">
									<v-row
										no-gutters
									>
										<v-col>
											<v-btn
												v-if="slotProps.item._mobile_link"
												icon
												rounded
												:href="slotProps.item._mobile_link"
											>
												<v-icon color="primary">
													mdi-phone
												</v-icon>
											</v-btn>
										</v-col>
										<v-col>
											<v-btn
												v-if="slotProps.item._email_link"
												icon
												rounded
												:href="slotProps.item._email_link"
											>
												<v-icon color="primary">
													mdi-email
												</v-icon>
											</v-btn>
										</v-col>
									</v-row>
								</template>
							</FilterableList>
						</template>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-container>

		<!-- overdue invoices -->
		<v-container
			class="container--narrow"
			v-for="(invoice) in overdueInvoices"
			:key="invoice.id"
		>
			<v-alert
				v-if="invoice._is_late"
				type="error"
				class="ma-0"
			>
				Vakuutuslaskusi on maksamatta. Siirry maksamaan se <router-link
					v-if="invoice.invoice_row_product.length"
					:to="{ name: 'company-invoice', params: { pagename: invoice.invoice_row_product[0].entity_ref, invoiceId: invoice.id } }"
					class="white--text"
				>
					tästä
				</router-link>.
				<small v-if="invoice.invoice_row_product.length"><br>
					{{ invoice.invoice_row_product[0].title }} - {{ invoice.invoice_row_product[0].row_info }}
				</small>
			</v-alert>
		</v-container>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import FilterableList from '@/components/FilterableList'

export default {
	name: 'MembershipCard',
	components: {
		FilterableList,
	},
	computed: {
		...mapState([
			'config',
			'user',
			'benefits',
			'companies',
		]),

		overdueInvoices () {
			var invoices = [].concat(...this.companies.map(({ invoice }) => invoice || []))

			return invoices.filter((invoice) => invoice._is_late)
		},
	},
	mounted () {
		this.$api.Me.doRequest()
	},
}
</script>
