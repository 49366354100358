<template>
	<div>
		<!-- This section is allowed only for members ages of 13-28 -->
		<template
			v-if="
				user._is_member === true &&
					user._age &&
					user._age >= 13 &&
					user._age <= 28
			"
		>
			<!-- Output child page -->
			<router-view :key="$route.fullPath" />

			<!-- Output navigation if we're not on child page -->
			<template v-if="$route.name == 'companyContainer'">
				<v-container class="container--narrow">
					<FilterableList
						v-if="companies && companies.length"
						:items="companies"
						:enable-search="false"
						icon-src="_icon"
						title-src="c_yritys_nimi"
						summary-src="_statustext"
						@itemClick="itemClick"
					/>
					<v-alert
						v-else
						type="info"
					>
						Sinulla ei ole 4H-yrityksiä.
					</v-alert>
					<v-btn
						color="primary"
						@click="$router.push({ name: 'companyAddNew' })"
					>
						<v-icon left>
							mdi-plus-circle
						</v-icon>
						Perusta uusi 4H-yritys
					</v-btn>
				</v-container>
			</template>
		</template>
		<v-container
			v-else
			class="container--narrow"
		>
			<v-alert type="info">
				Voit perustaa 4H-yrityksen, jos olet 13–28 -vuotias jäsen.
			</v-alert>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'
import FilterableList from '@/components/FilterableList'

export default {
	name: 'CompanyContainer',
	components: {
		FilterableList,
	},
	computed: {
		...mapState({
			user: state => state.user,
			companies: state => state.companies,
		}),
	},
	mounted () {
		this.$api.Companies.doRequest()
	},
	methods: {
		itemClick (item) {
			this.$router.push({ name: 'company', params: { pagename: item.name } })
		},
	},
}
</script>
